var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"r-dropdown",attrs:{"tabindex":"-1"},on:{"focusout":_vm.handleFocusOut}},[_c('div',{ref:"dropdown",staticClass:"r-dropdown__dropdown",on:{"click":_vm.toggleShown}},[_vm._t("default")],2),_c('portal',{attrs:{"to":"main-portal"}},[_c('transition',{attrs:{"name":"unroll"}},[(_vm.isMenuShown)?_c('div',{staticClass:"r-dropdown__menu",style:({
          width: _vm.width ? `${_vm.width}px` : 'auto',
          maxWidth: `${_vm.menuMaxWidth}px`,
          [_vm.stickTo]: `${_vm.left || _vm.right}`,
          top: `${_vm.top}px`,
          minWidth: `${_vm.minWidth}px`
        })},[(_vm.menuTitle)?_c('r-title',{attrs:{"type":"subtitle-2"}},[_vm._v(" "+_vm._s(_vm.menuTitle)+" ")]):_vm._e(),_vm._t("dropdown-menu")],2):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }