const urlCollection = {
  demo: 'https://demo.ritm3.ru',
  new: 'https://new.ritm3.ru',
  surgut: 'https://surgut.ritm3.ru',
  prod: ''
}

const URL =
  process.env.NODE_ENV === 'development'
    ? urlCollection.surgut // <<-- Toggle URL here
    : urlCollection.prod

export default {
  MAIN_TITLE: 'RITM³',
  URL,
  SOCKET_URL: URL
}
