<template>
  <label class="r-checkbox">
    <input
      :id="id"
      :checked="true"
      class="r-checkbox__input"
      type="checkbox"
      @change="handleChange"
      @click="$emit('click')"
    />
    <checkbox-status :state="state" />
    <r-text v-if="label">
      {{ label }}
    </r-text>
    <r-text v-else>
      <slot />
    </r-text>
  </label>
</template>

<script>
import CheckboxStatus from '@/components/r-ui/r-select/components/checkbox-status'

export default {
  components: { CheckboxStatus },
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    indeterminate: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isChecked: this.checked
    }
  },
  computed: {
    state() {
      if (this.indeterminate) return 'indeterminate'
      return this.isChecked ? 'checked' : 'unchecked'
    }
  },
  watch: {
    checked(value) {
      this.isChecked = value
    },
    isChecked(value) {
      this.$emit('change', value)
    }
  },
  methods: {
    handleChange() {
      this.$emit('change', !this.isChecked)
    }
  }
}
</script>

<style lang="scss" scoped>
.r-checkbox {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.25rem;
  cursor: pointer;
  align-items: center;

  &__input {
    display: none;
  }

  .r-text {
    white-space: nowrap;
  }
}
</style>
