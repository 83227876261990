<template>
  <el-pagination
    class="r-pagination"
    :class="{ small }"
    :current-page.sync="currentPage"
    :page-size="pageSize"
    :small="small"
    :layout="layout"
    :total="count"
    :pager-count="pagerCount"
    :hide-on-single-page="hideOnSinglePage"
    @current-change="$emit('current-change', currentPage)"
  />
</template>

<script>
export default {
  name: 'RPagination',
  props: {
    page: {
      type: Number,
      default: 1
    },
    count: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 10
    },
    pagerCount: {
      type: Number,
      default: 5
    },
    hideOnSinglePage: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    tiny: {
      type: Boolean,
      default: false
    },
    layout: {
      type: String,
      default: 'prev, pager, next'
    }
  },
  data() {
    return {
      currentPage: this.page
    }
  }
}
</script>

<style lang="scss">
.r-pagination {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  align-items: center;

  &.small {
    .el-pager .number {
      width: 25px;
    }
  }

  .el-pagination__sizes {
    margin: 0;

    .el-input__inner {
      height: 36px;
      min-height: 36px;
    }
  }

  &.el-pagination {
    button {
      background-color: transparent !important;
      color: var(--text-primary) !important;
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: var(--border-radius);

      &:hover,
      &.active {
        color: var(--accent-primary);
      }
    }

    .el-pager {
      display: flex;
    }

    li {
      width: 36px;
      height: 36px;
      background-color: transparent;
      color: var(--text-primary);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: var(--border-radius);
      margin-right: 2px;

      &:last-child {
        margin-right: 0;
      }

      &:hover,
      &.active {
        background-color: var(--accent-primary-2);
      }
    }

    .el-input__inner {
      background-color: $field-bg;
      border-color: $field-border;
      color: $text-primary;
    }
  }
}
</style>
