<template>
  <div
    class="r-checkbox-group"
    @change="handleChange"
  >
    <r-checkbox
      v-if="hasSelectAll"
      :checked="isSelectAllChecked"
      :indeterminate="indeterminate"
      @click="handleSelectAllChange"
    >
      &#60;{{ $t('select_all') }}&#62;
    </r-checkbox>
    <!--  checkboxes in checkbox group slot must have IDs-->
    <slot />
  </div>
</template>

<script>
export default {
  name: 'RCheckboxGroup',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    hasSelectAll: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      indeterminate: true
    }
  },
  computed: {
    isSelectAllChecked() {
      return (
        this.value.length === this.$children.filter(child => child.id).length
      )
    }
  },
  watch: {
    value() {
      this.setCheckboxesValues()
    }
  },
  mounted() {
    this.setCheckboxesValues()
  },
  methods: {
    setIndeterminate() {
      const checks = this.$children.filter(child => child.id)

      this.indeterminate = !!(
        this.value.length && this.value.length < checks.length
      )
    },
    setCheckboxesValues() {
      this.$children
        .filter(child => child.id)
        .forEach(child => (child.isChecked = this.value.includes(child.id)))

      this.setIndeterminate()
    },
    handleChange(evt) {
      if (!evt.target.id) return

      const targetCheckbox = this.$children.find(
        child => child.id === evt.target.id
      )

      targetCheckbox.isChecked = !targetCheckbox.isChecked
      const ids = this.$children
        .filter(child => child.id && child.isChecked)
        .map(child => child.id)

      this.$emit('change', ids)
    },
    handleSelectAllChange() {
      this.indeterminate = false

      if (this.isSelectAllChecked) return this.$emit('change', [])

      const ids = this.$children
        .filter(child => child.id)
        .map(child => child.id)

      this.$emit('change', ids)
    }
  }
}
</script>

<style lang="scss" scoped>
.r-checkbox-group {
  @include grid-column(0.25rem);
}
</style>
