import { getCookie } from '../helpers/cookies'

export default {
  locale: 'ru',
  theme: 'light',
  defaultPath: '/app',

  notification: null,

  token: getCookie('token'),
  user: null,
  userId: null,
  userFullname: null,
  isCitizen: false,
  isAdmin: false,

  endpoint: 'road_works',

  loading: false,

  initialState: {
    baseLayer: 'streets',
    center: [73.397, 61.25],
    zoom: 12
  },

  services: {},

  createForm: null,

  cardComponent: null,
  cardResize: false,

  showVehicles: false,
  showWorkers: false,

  orderId: null,
  updateOrders: false,

  statusFilter: 0,

  handleCloseCard: false,

  flyToGeom: null,

  editorState: false,
  clearGeom: false,
  selectedPoint: null,

  orderFields: null,
  formRules: null
}
