<script>
export default {
  name: 'RenderOption',
  props: {
    dom: {
      type: Array,
      required: true
    }
  },
  render: function (createElement) {
    return createElement(
      'div',
      {
        attrs: { class: 'render-option' }
      },
      this.dom
    )
  }
}
</script>

<style lang="scss" scoped>
.render-option {
  width: 100%;
  display: flex;
}
</style>
