<template>
  <div
    :class="{ indent }"
    class="r-ritm-tabs"
  >
    <ul
      :class="{ metro, simple, vertical }"
      class="r-ritm-tabs__list"
    >
      <li
        v-for="tab in tabs"
        :key="tab.id"
        class="list-item__header"
        :class="{ active: tab.isActive }"
        @click="setActive(tab.id)"
      >
        <r-icon
          v-if="tab.icon"
          :name="tab.icon"
          :size="18"
        />
        <span class="list-item__header-name">
          {{ tab.name || '' }}
        </span>
      </li>
    </ul>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    metro: {
      type: Boolean,
      default: false
    },
    indent: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: false
    },
    simple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tabs: [],
      currentTab: ''
    }
  },
  watch: {
    value(v) {
      if ((v || v === 0) && v !== this.currentTab) this.setActive(v)
    }
  },
  mounted() {
    this.tabs = this.$children.filter(e => e.thisIsTab)
    const v = this.value

    if (v || v === 0) {
      this.setActive(v)
      this.currentTab = v
    } else {
      const tab = this.$children.find(tab => tab.thisIsTab)
      if (tab) {
        tab.setActive(true)
      }
    }
  },
  methods: {
    setActive(id) {
      this.$children.forEach(tab => {
        if (tab.thisIsTab) {
          tab.setActive(tab.id === id)
        }
      })
      this.currentTab = id
      this.$emit('input', id)
    }
  }
}
</script>

<style lang="scss">
.r-ritm-tabs {
  display: grid;
  grid-gap: 0.5rem;
  height: 100%;
  overflow: hidden;
  align-content: start;

  &.indent {
    .r-ritm-tabs__list-item {
      padding: 0.5rem !important;
    }
  }

  &__list {
    padding: 0.25rem;
    border-radius: $border-radius;
    display: grid;
    align-items: center;
    justify-items: stretch;
    width: 100%;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(80px, auto));
    justify-content: stretch;
    background-color: $bg-containers;

    .list-item {
      &__header {
        height: 1.75rem;
        display: inline-grid;
        align-items: center;
        grid-auto-flow: column;
        grid-gap: 0.25rem;
        padding: 0 0.5rem;
        justify-content: center;
        border-radius: $border-radius;
        transition: ease 0.2s;

        &-name {
          color: $text-primary;
          font-size: 14px;
          user-select: none;
          line-height: 1.75rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &:hover {
          cursor: pointer;
          background-color: $field-hover-bg;
        }

        &.active {
          background-color: $accent-primary-1;
        }
      }
    }

    &.metro {
      padding: 0;
      grid-gap: 0;
      background-color: transparent;

      .list-item {
        &__header {
          height: 2.25rem;
          border-radius: 0;
          border-bottom: 2px solid transparent;

          &-name {
            font-size: 1rem;
          }

          &:hover {
            background-color: $field-hover-bg;
          }

          &.active {
            border-bottom-color: $accent-primary;
            background-color: $accent-primary-1;
          }
        }
      }
    }

    &.simple {
      background-color: transparent;
    }

    &.vertical {
      grid-template-columns: 1fr;
    }
  }
}
</style>
