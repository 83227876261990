export default {
  SET(state, [field, value]) {
    if (field.includes('.')) {
      const subField = field.split('.')
      state[subField[0]][subField[1]] = value
    } else {
      state[field] = value
    }
  },
  SYNC_STORAGE(state) {
    const initialState = JSON.stringify(state.initialState)

    localStorage.setItem('initialState', initialState)
    localStorage.setItem('theme', state.theme)
    localStorage.setItem('locale', state.locale)
  },
  CLEAR_USER_DATA(state) {
    state.token = undefined
    state.user = undefined
    state.orderFields = null
    state.formRules = null
    state.services = {}
  }
}
