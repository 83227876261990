<template>
  <button
    :class="['r-delete-button', cross ? 'simple' : '', { mini, tiny }]"
    @click="beforeDelete"
  >
    <r-icon
      :name="cross ? 'close-delete' : 'trash'"
      :size="tiny ? 18 : 22"
      :color-type="cross ? 'default' : 'danger'"
    />
    <span
      v-if="!mini && !tiny"
      class="r-delete-button__text"
    >
      {{ $t('r-delete-object') }}
    </span>
    <div class="r-delete-button__text">
      <slot />
    </div>
  </button>
</template>

<script>
export default {
  props: {
    mini: {
      type: Boolean,
      default: () => false
    },
    tiny: {
      type: Boolean,
      default: () => false
    },
    cross: {
      type: Boolean,
      default: () => false
    },
    simple: {
      type: Boolean,
      default: () => false
    },
    source: {
      type: String,
      default: () => null
    },
    id: {
      type: String,
      default: () => null
    }
  },
  methods: {
    beforeDelete() {
      if (this.simple) {
        this.$emit('delete')
        return
      }
      this.$confirm(this.$t('r-delete.info'), this.$t('r-delete.question'), {
        customClass: 'r-message-box',
        type: 'warning',
        closeOnPressEscape: false,
        closeOnClickModal: false
      })
        .then(() => {
          this.$emit('beforeDelete')
        })
        .then(async () => {
          const { source_id, id } = this
          if (source_id && id) {
            await this.deleteRequest(source_id, id)
          } else {
            this.$emit('delete')
          }
        })
        .then(() => {
          const message = this.$t('r-delete.succsess-message')
          this.$notify({ type: 'success', message })
          this.$emit('afterDelete')
        })
    },
    async deleteRequest(source_id, id) {
      await this.DELETE(`${source_id}/${id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.r-delete-button {
  display: grid;
  justify-content: center;
  grid-auto-flow: column;
  align-items: center;
  border-radius: $border-radius;
  background-color: transparent;
  border: none;
  height: 36px;
  grid-gap: 0.5rem;
  transition: background-color ease 0.15s;
  padding: 0 0.5rem;
  margin: 0;
  cursor: pointer;

  &__text {
    max-width: 100%;
    color: $accent-danger;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 600;

    &:empty {
      display: none;
    }
  }

  &.tiny {
    width: 28px;
    height: 28px;
    padding: 0;
  }

  &.simple {
    height: auto;

    .r-delete-button__text {
      color: $text-primary;
    }

    &:hover {
      .r-delete-button__text {
        color: $accent-danger;
      }

      .r-icon {
        fill: $accent-danger !important;
      }
    }
  }

  &:hover {
    background-color: $accent-danger-hover;
  }
}
</style>

<i18n>
{
  "ru": {
    "r-delete-object": "Удалить",
    "r-delete.error-title": "Ошибка!",
    "r-delete.info": "Это действие невозможно отменить.",
    "r-delete.question": "Удалить объект?",
    "r-delete.error-message":
      "Удаление не выполнено, обратитесь к администратору",
    "r-delete.succsess-message": "Удаление выполнено"
  },
  "en": {
    "r-delete-object": "Delete",
    "r-delete.error-title": "Error!",
    "r-delete.info": "This action cannot be cancelled.",
    "r-delete.question": "Delete object?",
    "r-delete.error-message": "Deletion failed, contact your administrator",
    "r-delete.succsess-message": "Deletion completed"
  }
}
</i18n>
