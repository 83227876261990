import Vue from 'vue'
import locale from 'element-ui/lib/locale'
import lang from '@/config/element_local'
import {
  Select,
  Option,
  DatePicker,
  TimePicker,
  Input,
  Checkbox,
  Radio,
  RadioButton,
  MessageBox,
  InputNumber,
  Upload,
  Pagination
} from 'element-ui'
import { Notification } from 'element-ui'

locale.use(lang)

const option = { size: 'small' }

Vue.use(Select, option)
Vue.use(Option, option)
Vue.use(DatePicker, option)
Vue.use(TimePicker, option)
Vue.use(Input, option)
Vue.use(Checkbox, option)
Vue.use(Radio, option)
Vue.use(RadioButton, option)
Vue.use(InputNumber, option)
Vue.use(Upload, option)
Vue.use(Pagination, option)

Vue.prototype.$notify = Notification
Vue.prototype.$confirm = MessageBox.confirm
