export const filterObjectByKeys = (obj, fields) => {
  return Object.entries(obj).reduce((acc, [k, v]) => {
    if (!fields.includes(k)) {
      acc[k] = v
    }
    return acc
  }, {})
}

export const cloneDeep = (obj, cache = new WeakMap()) => {
  if (typeof obj !== 'object' || obj === null) return obj

  if (cache.has(obj)) return cache.get(obj)

  const clone = Array.isArray(obj) ? [] : {}
  cache.set(obj, clone)

  for (let key in obj) {
    clone[key] = cloneDeep(obj[key], cache)
  }

  return clone
}
