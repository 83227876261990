<template>
  <div class="r-simple-card">
    <div
      :class="['r-simple-card__header', { noToggler }]"
      @click="toggleHandler()"
    >
      <r-icon
        v-if="iconName"
        :name="iconName"
        :size="20"
      />
      <div class="r-simple-card__title">
        <r-title
          v-if="title"
          type="subtitle-2"
        >
          {{ title }}
        </r-title>
        <r-text
          v-if="subtitle"
          type="caption"
        >
          {{ subtitle }}
        </r-text>
      </div>
      <div
        v-if="!noToggler"
        class="r-simple-card__toggler"
      >
        <r-icon
          :name="isOpen ? 'chevron-up' : 'chevron-down'"
          :size="20"
        />
      </div>
    </div>
    <transition
      name="fadeHeight"
      mode="out-in"
    >
      <div
        v-if="isOpen"
        class="r-simple-card__content"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    noToggler: {
      type: Boolean,
      default: false
    },
    defaultClosed: {
      type: Boolean,
      default: false
    },
    iconName: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isOpen: this.noToggler || !this.defaultClosed
    }
  },
  methods: {
    toggleHandler() {
      if (this.noToggler) return

      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style lang="scss">
.r-simple-card {
  position: relative;
  padding: 8px;
  background-color: $bg-containers !important;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  width: 100%;

  &__header {
    width: 100%;
    cursor: pointer;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-self: start;
    grid-gap: 0.5rem;

    .r-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block !important;
    }

    &.noToggler {
      cursor: default;
    }
  }

  &__content {
    padding: 0.5rem 0;
    width: 100%;
  }
}
</style>
