<template>
  <ul class="r-attributes">
    <li
      v-for="{ title, value } in data"
      :key="title"
      class="r-attributes__item"
    >
      <div class="r-attributes__cell r-attributes__cell--label">
        <r-text type="caption">
          {{ title }}
        </r-text>
      </div>
      <div class="r-attributes__cell r-attributes__cell--value">
        <r-text>
          {{ value }}
        </r-text>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss">
.r-attributes {
  @include border-top;

  &__item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    @include border-bottom;
  }

  &__cell {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 4px 8px;
    @include border-left;

    &--value {
      flex: 1;
    }

    &:last-child {
      @include border-right;
    }
  }
}
</style>
