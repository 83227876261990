<template>
  <button :class="[`r-icon-button `, { active }]">
    <r-icon
      :name="icon"
      :size="size"
    />
    <span
      v-if="caption"
      class="r-icon-button__caption"
    >
      {{ caption }}
    </span>
  </button>
</template>

<script>
export default {
  props: {
    icon: {
      required: true,
      type: String
    },
    caption: {
      required: false,
      type: String,
      default: () => {}
    },
    size: {
      type: Number,
      default: 20
    },
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.r-icon-button {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-auto-flow: row;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 0.25rem;
  height: 100%;
  transition: 0.15s;
  outline: none !important;

  &.active {
    background-color: $button-secondary-bg;
  }

  &:focus,
  &:hover,
  &:active {
    background-color: transparent !important;
  }

  &__caption {
    font-size: 10px;
    color: $text-secondary;
  }
}
</style>
