const optimize = true

export default [
  {
    id: 1,
    label: 'Mapbox Basic',
    link: `mapbox://styles/mapbox/basic-v9${optimize ? '?optimize=true' : ''}`,
    name: 'basic'
  },
  {
    id: 2,
    label: 'Mapbox Streets',
    link: `mapbox://styles/aplussmoscow/ckys4in5t58p315mwhlmxd06o${
      optimize ? '?optimize=true' : ''
    }`,
    name: 'streets'
  },
  {
    id: 3,
    label: 'Mapbox Traffic',
    link: `mapbox://styles/mapbox/traffic-night-v2${
      optimize ? '?optimize=true' : ''
    }`,
    name: 'traffic'
  },
  {
    id: 4,
    label: 'Mapbox Light',
    link: `mapbox://styles/aplussmoscow/ckmc46ynchrrd17o5786jjy7t${
      optimize ? '?optimize=true' : ''
    }`,
    name: 'light'
  },
  {
    id: 5,
    label: 'Mapbox Dark',
    link: `mapbox://styles/aplussmoscow/cl5jbvkfr001d14qehc4m9wkw${
      optimize ? '?optimize=true' : ''
    }`,
    name: 'dark'
  },
  {
    id: 6,
    label: 'Mapbox Dark',
    link: `mapbox://styles/mapbox/dark-v9${optimize ? '?optimize=true' : ''}`,
    name: 'simple-dark'
  },
  {
    id: 7,
    label: 'Mapbox Satellite Streets',
    link: `mapbox://styles/mapbox/satellite-streets-v9${
      optimize ? '?optimize=true' : ''
    }`,
    name: 'satellite-streets'
  },
  {
    id: 8,
    label: 'Mapbox Satellite Streets',
    link: `mapbox://styles/aplussmoscow/ckea67vfv6oxu1aoxuaikbgvm${
      optimize ? '?optimize=true' : ''
    }`,
    name: 'dark-blue'
  }
]
