<template>
  <div
    :class="[`r-title ${type} ${colorType}`, { center }]"
    :style="getStyle()"
  >
    <slot />
  </div>
</template>

<script>
const titleTypes = [
  'title-0',
  'title-1',
  'title-2',
  'title-3',
  'subtitle-1',
  'subtitle-2'
]

export default {
  props: {
    type: {
      validator: value => titleTypes.includes(value),
      default: 'title-1'
    },
    color: {
      type: String,
      default: null
    },
    center: {
      type: Boolean,
      default: false
    },
    colorType: {
      type: String,
      default: ''
    },
    weight: {
      type: Number,
      default: null
    }
  },
  methods: {
    getStyle() {
      const styles = {}

      if (this.color) styles.color = `${this.color} !important`

      if (this.weight) styles['font-weight'] = `${this.weight} !important`

      return styles
    }
  }
}
</script>

<style lang="scss">
.r-title {
  color: $text-primary;
  display: flex;
  align-items: center;

  &.center {
    text-align: center;
    justify-content: center;
  }

  &.title-0 {
    font-weight: 700;
    line-height: 1.5;
    font-size: 24px;
    color: $text-subhead;
  }

  &.title-1 {
    font-weight: 600;
    line-height: 1.5;
    font-size: 18px;
  }

  &.title-2 {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;
  }

  &.title-3 {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
  }

  &.subtitle-1 {
    font-weight: 600;
    line-height: 1.5;
    font-size: 14px;
  }

  &.subtitle-2 {
    font-size: 12px;
    line-height: 1.5;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  &.subhead {
    color: $text-subhead !important;
  }

  &.secondary {
    color: $text-secondary !important;
  }

  &.primary {
    color: $text-primary !important;
  }

  &.accent-primary {
    color: $accent-primary !important;
  }

  &.danger,
  &.error {
    color: $accent-danger !important;
  }

  &.warning {
    color: $accent-warning !important;
  }

  &.success {
    color: $accent-success !important;
  }
}
</style>
