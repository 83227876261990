<template>
  <div
    class="r-dropdown"
    tabindex="-1"
    @focusout="handleFocusOut"
  >
    <div
      ref="dropdown"
      class="r-dropdown__dropdown"
      @click="toggleShown"
    >
      <slot />
    </div>
    <portal to="main-portal">
      <transition name="unroll">
        <div
          v-if="isMenuShown"
          class="r-dropdown__menu"
          :style="{
            width: width ? `${width}px` : 'auto',
            maxWidth: `${menuMaxWidth}px`,
            [stickTo]: `${left || right}`,
            top: `${top}px`,
            minWidth: `${minWidth}px`
          }"
        >
          <r-title
            v-if="menuTitle"
            type="subtitle-2"
          >
            {{ menuTitle }}
          </r-title>

          <slot
            name="dropdown-menu"
            class="r-dropdown__menu-list"
          />
          <!-- @click="menuClickHandler" -->
        </div>
      </transition>
    </portal>
  </div>
</template>

<script>
export default {
  props: {
    menuTitle: {
      type: String,
      default: ''
    },
    menuMaxWidth: {
      type: Number,
      default: 300
    },
    width: {
      type: Number,
      default: null
    },
    stickTo: {
      type: String,
      default: 'left'
    },
    hideMenuOnClick: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isMenuShown: false,
      top: null,
      left: null,
      right: null,
      minWidth: null
    }
  },
  mounted() {
    this.updateMenuCoords()
    document.addEventListener('scroll', this.close, true)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.close, true)
  },
  methods: {
    toggleShown() {
      this.updateMenuCoords()
      this.isMenuShown = !this.isMenuShown
      if (!this.isMenuShown)
        window.removeEventListener('click', this.menuClickHandler)
    },
    close() {
      this.isMenuShown = false
    },
    handleFocusOut() {
      window.addEventListener('click', this.menuClickHandler, { once: true })
    },
    destroy() {
      window.removeEventListener('click', this.menuClickHandler)
    },
    menuClickHandler(e) {
      if (!e.target.closest('.r-dropdown-child')) {
        this.isMenuShown = false
      }
    },
    updateMenuCoords() {
      if (!this.$refs.dropdown) return
      const rect = this.$refs.dropdown.getBoundingClientRect()
      this.top = rect.bottom + 8
      this.minWidth = rect.width

      switch (this.stickTo) {
        case 'left':
          this.left = `${rect.left}px`
          break
        default:
          this.right = `calc(100vw - ${rect.right}px)`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.r-dropdown {
  &__dropdown {
    display: flex;
    z-index: 100;
  }

  &__menu {
    background: $field-droplist-bg;
    position: absolute;
    box-shadow: $box-shadow-m;
    border-radius: 4px;
    top: 44px;
    // right: 0;
    border: 1px solid $field-border;
    min-width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 250px;
    display: grid;
    width: max-content;
    z-index: 8888;
  }

  &__menu-list {
    padding: 0 0 0.5rem;
  }

  .r-title {
    padding: 0.5rem;
  }
}

.unroll-enter-active,
.unroll-leave-active {
  transition: all 0.15s ease;
  overflow: hidden;
  display: block;
  max-height: 250px;
  opacity: 1;
}

.unroll-enter,
.unroll-leave-to {
  max-height: 0;
  opacity: 0.5;
}
</style>
