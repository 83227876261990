<template>
  <table :class="['r-table', { sorting }]">
    <thead
      v-if="!noHead"
      @[handler]="getSort($event)"
    >
      <tr>
        <th
          v-if="numeric"
          data-row="tOrder"
          :style="{ flex: '0 0 50px' }"
        >
          <r-title type="subtitle-2"> № </r-title>
          <r-icon
            :name="getIcon('tOrder')"
            :color="getColor('tOrder')"
            class="r-table__icon"
            :size="20"
          />
        </th>
        <th
          v-for="({ title, width = 0 }, key) in columns"
          :key="title"
          :data-row="key"
          :style="{ width: `${width}px` }"
        >
          <r-title type="subtitle-2">
            {{ title }}
          </r-title>
          <r-icon
            v-if="sorting && !hasSlot(key)"
            :name="getIcon(key)"
            :color="getColor(key)"
            class="r-table__icon"
            :size="20"
          />
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(row, ndx) in tableData"
        :key="ndx"
      >
        <td
          v-if="numeric"
          :style="{ flex: '0 0 50px' }"
        >
          <r-text>
            {{ row.tOrder }}
          </r-text>
        </td>

        <td
          v-for="({ width = 0 }, key) in columns"
          :key="key"
          :style="{ width: `${width}px` }"
        >
          <slot
            v-if="hasSlot(key)"
            :row="row"
            :name="key"
          />
          <r-text
            v-else
            class="r-table__cell"
          >
            {{ valueValidation(row[key]) }}
          </r-text>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    columns: {
      type: Object,
      default: null
    },
    sorting: {
      type: Boolean,
      default: false
    },
    noHead: {
      type: Boolean,
      default: false
    },
    numeric: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sortedRow: null
    }
  },
  computed: {
    tableData() {
      return this.data.map((item, ndx) => ({
        ...item,
        tOrder: ndx + 1
      }))
    },
    handler() {
      return this.sorting ? 'click' : null
    }
  },
  methods: {
    hasSlot(key) {
      return Object.keys(this.$scopedSlots).includes(key)
    },
    getIcon(title) {
      if (!this.sortedRow) return 'sort-ascending'
      const { row, direction } = this.sortedRow
      return row === title && direction === 1
        ? 'sort-ascending'
        : row === title
        ? 'sort-descending'
        : 'sort-ascending'
    },
    getColor(title) {
      if (!this.sortedRow) return 'var(--dividers-high-contrast)'
      const { row } = this.sortedRow
      return row === title
        ? 'var(--accent-primary)'
        : 'var(--dividers-high-contrast)'
    },
    valueValidation(value) {
      if (value !== 0 && !value) return '—'
      else return value
    },
    getSort({ target }) {
      const thTarget = target.closest('th')
      const direction = (thTarget.dataset.direction = -(
        thTarget.dataset.direction || -1
      ))
      const row = thTarget.dataset.row
      const collator = new Intl.Collator('ru-RU', { numeric: true })
      this.tableData.sort((a, b) => {
        return direction * collator.compare(a[row], b[row])
      })
      this.sortedRow = { row, direction }
    }
  }
}
</script>

<style lang="scss" scoped>
.r-table {
  width: 100%;
  color: $text-primary;
  overflow: auto;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  @include border-top;
  @include border-left;

  thead {
    width: 100%;
    display: grid;
    border-bottom: 1px solid $table-border;
  }

  tbody {
    overflow-y: overlay;
    overflow-x: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  tr {
    background: none !important;
    display: flex;
    width: 100%;
  }

  th {
    background-color: $table-header-bg !important;
    flex: 1 0 auto;
    min-height: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-right: 1px solid $table-border;
    padding: 0.25rem 0.5rem;
    position: relative;
    user-select: none;
  }

  td {
    padding: 0.25rem 0.5rem !important;
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    border-right: 1px solid $table-border;
    border-bottom: 1px solid $table-border;
  }

  &__cell {
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    flex: 1;
  }

  &__icon {
    position: absolute;
    right: 4px;
  }

  &.sorting {
    thead {
      cursor: pointer;
    }

    th {
      padding-right: 24px;
    }
  }
}
</style>
