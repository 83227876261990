<template>
  <li
    :id="id"
    :class="[
      'r-select-option',
      isSelected ? 'active' : '',
      isFocused ? 'focus' : '',
      disabled ? 'disabled' : '',
      multiple ? 'multiple' : '',
      $parent.hasNoTextData ? 'has-no-text-data' : ''
    ]"
    @click.stop="$emit('click', $event)"
  >
    <slot />
    <render-option
      v-if="!$slots.default && html"
      :dom="slot"
    />
    <div class="r-select-option__item-wrapper">
      <r-checkbox
        v-if="multiple && !html"
        :checked="isSelected"
        :white-space="mode === 'wrap' ? 'unset' : 'nowrap'"
        :label="title"
        @click="$emit('click', $event)"
      />
      <r-text
        v-else
        :title="title"
      >
        {{ title }}
      </r-text>
    </div>
  </li>
</template>

<script>
import RenderOption from './r-select/components/render-option'

export default {
  name: 'RSelectOption',
  components: { RenderOption },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    html: {
      type: Array,
      default: null
    }
  },
  computed: {
    slot() {
      return this.html ? this.html[0].componentOptions.children : null
    },
    isSelected() {
      if (!this.multiple) {
        return this.$parent.active === this.id
      } else if (this.multiple && this.$parent.hasNoTextData) {
        return this.$parent.active[0] === this.id
      } else {
        return this.$parent.active.some(item => item === this.id)
      }
    },
    multiple() {
      return this.$parent.multiple
    },
    mode() {
      return this.$parent.mode
    },
    isFocused() {
      return this.id === this.$parent.focusedElementId
    }
  }
}
</script>

<style lang="scss" scoped>
.r-select-option {
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  width: 100%;
  align-items: center;
  grid-gap: 0.5rem;
  overflow: hidden;
  border-radius: $border-radius;
  margin-top: 0.25rem;

  .r-text {
    white-space: nowrap;
  }

  .r-tooltip__wrapper,
  .r-select-option__item-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.multiple {
    padding-left: 12px;

    &.active {
      background: unset;
    }

    &:hover {
      background: $field-hover-bg;
    }
  }

  &.multiple.has-no-text-data {
    &.active {
      background: $accent-primary-1;
    }
  }

  &.focus {
    background: $field-hover-bg !important;
  }

  &:hover {
    background: $field-hover-bg;
  }

  &.active {
    background: $accent-primary-1;
  }

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.wrap .r-select-option {
  .r-text {
    white-space: unset;
  }
}
</style>
