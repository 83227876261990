<template>
  <div
    class="r-select"
    :class="{
      disabled,
      error,
      'dropdown-style': dropdownStyle,
      'show-all': showAllSelectedItems,
      opened: isMenuOpened
    }"
  >
    <r-text
      v-if="label"
      color-type="subhead"
    >
      {{ label }}
    </r-text>
    <div
      ref="wrapper"
      class="r-select__input-wrapper"
      tabindex="0"
      :class="{ angular }"
      :style="{ maxWidth: maxWidth, width: `${width}px` }"
      @click="inputClickHandler"
      @mouseenter="isClearButtonShowing = true"
      @mouseleave="isClearButtonShowing = false"
    >
      <div
        v-if="!hasNoTextData"
        class="r-select__selected-items"
      >
        <r-text
          v-for="(id, i) in showedItems"
          :key="i"
          :size="12"
          class="r-select__selected-item"
          @click="deleteSelectedItem(id)"
        >
          {{ getOptionTitleById(id) }}
        </r-text>
        <div
          v-if="multiple && active.length > 2 && !showAllSelectedItems"
          class="r-select__selected-item r-select__selected-item--more"
        >
          <r-text
            :size="12"
            color-type="primary"
          >
            {{ `+${active.length - 2}` }}
          </r-text>
        </div>
        <input
          v-if="isFilterable"
          ref="filter"
          v-model="text"
          :title="text"
          tabindex="-1"
          class="r-select__input"
          type="text"
          :disabled="disabled"
          :placeholder="inputPlaceholder"
          @input="filterInputHandler"
        />
        <r-text
          v-else
          class="r-select__input"
        >
          {{ text }}
        </r-text>
      </div>
      <render-option
        v-else-if="selectedOption"
        :dom="selectedOption.html[0].componentOptions.children"
      />
      <r-button
        v-if="loading"
        class="r-select__clear-button"
        loading
        simple
        mini
      />
      <r-button
        v-else-if="isClearButton"
        simple
        class="r-select__clear-button"
        mini
        icon="clear-input"
        @click.native.stop="clearButtonClickHandler"
      />
      <r-button
        v-else
        tabindex="-1"
        class="r-select__dropdown-icon"
        :icon="{ name: dropdownStyle ? '' : 'chevron-down', size: 16 }"
        :dropdown="dropdownStyle"
        mini
        simple
        :disabled="disabled"
        :type="dropdownStyle ? 'primary' : 'default'"
        @click.native.stop="toggleMenu"
      />
    </div>

    <div v-show="false">
      <slot />
    </div>

    <portal to="main-portal">
      <transition
        name="unroll"
        mode="in-out"
      >
        <select-menu
          v-if="isMenuOpened"
          ref="menu"
          :loading="loading"
          :top="menuTop"
          :bottom="menuBottom"
          :left="menuLeft"
          :width="menuWidth"
          :filtered-options="filteredOptions"
          :multiple="multiple"
          :active="active"
          :mode="mode"
          :has-no-text-data="hasNoTextData"
          :no-select-all="noSelectAll"
          :service-options="!!serviceOptions"
          :filter-text="text"
          :cache-query="cacheQuery"
          @item-click="itemClickHandler"
          @select-all="selectAll"
        />
      </transition>
    </portal>
  </div>
</template>

<script>
import SelectMenu from './r-select/components/select-menu'
import RenderOption from '@/components/r-ui/r-select/components/render-option'
import { debounce, isNumber } from 'HELPERS'
import eventBus, { eventNames } from 'EVENT_BUS'
const { SELECT_OPEN } = eventNames

export default {
  components: { RenderOption, SelectMenu },
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: 'Выберите пункт'
    },
    selected: {
      type: [String, Array, Number],
      default: ''
    },
    filterable: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    angular: {
      type: Boolean,
      default: false
    },
    dropdownStyle: {
      type: Boolean,
      default: false
    },
    showAllSelectedItems: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: '100%'
    },
    label: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: null
    },
    mode: {
      // ellipsis, tooltip, wrap
      type: String,
      default: 'ellipsis'
    },
    serviceOptions: {
      type: Object,
      default: () => null
    }
  },
  data() {
    const selected = this.options.find(item => item.id === this.selected)

    return {
      uid: null,
      loading: false,
      filterInputText: this.multiple ? '' : selected?.title || selected?.name,
      isMenuOpened: false,
      active: this.selected,
      isClearButtonShowing: false,
      menuTop: null,
      menuBottom: null,
      menuLeft: null,
      menuWidth: null,
      slotOptions: null,
      hasNoTextData: false,
      endpointOptions: [],
      endpointDataCache: {},
      cacheLabel: null,
      text: this.multiple ? '' : selected?.title || selected?.name,
      cacheQuery: null
    }
  },
  computed: {
    opt() {
      if (this.serviceOptions) {
        return this.endpointOptions
      } else if (this.$slots.default) {
        return this.slotOptions
      } else {
        return this.options
      }
    },
    isFilterable() {
      return this.filterable || this.serviceOptions
    },
    filteredOptions() {
      if (this.serviceOptions) return this.opt

      if (!this.isFilterable || !this.text || this.hasNoTextData)
        return this.opt

      const query = this.text.toLowerCase()
      return this.opt.filter(item => {
        const name = item.title || item.name
        return name.toLowerCase().includes(query)
      })
    },
    inputPlaceholder() {
      if (this.serviceOptions && !this.multiple) {
        return this.cacheLabel || this.$t('enter-keyword')
      } else if (this.multiple) {
        return this.active.length
          ? ''
          : this.serviceOptions
          ? this.$t('enter-keyword')
          : this.placeholder
      } else {
        return this.placeholder
      }
    },
    isClearButton() {
      if (this.disabled) return false
      if (this.clearable && this.isMenuOpened && this.text) return true
      return (
        this.clearable &&
        (this.multiple ? this.active[0] : this.active || this.text) &&
        this.isClearButtonShowing
      )
    },
    showedItems() {
      if (!this.multiple) return []
      return this.showAllSelectedItems ? this.active : this.active.slice(0, 2)
    },
    selectedOption() {
      if (this.multiple) {
        return this.opt?.find(item => {
          return item.id === this.selected[0]
        })
      } else {
        return this.opt?.find(item => item.id === this.selected)
      }
    },
    noSelectAll() {
      return !!this.serviceOptions
    }
  },
  watch: {
    options() {
      if (!this.multiple) {
        const option = this.opt.find(item => item.id === this.selected)
        this.text = option?.title || option?.name
      }
    },
    selected(value) {
      this.active = value

      if (!this.multiple) {
        const option = this.opt.find(item => item.id === value)
        this.text = option?.title || option?.name
        this.cacheLabel = option?.title || option?.name
      }
    },
    filterInputText() {
      if (!this.serviceOptions) return

      const query = this.text?.toLowerCase()?.trim()

      if (!query || query?.length < 3) {
        this.endpointOptions = []
      } else {
        this.loading = true

        this.getEndpointData()
      }
    }
  },
  created() {
    this.uid = (+new Date()).toString(16)

    if (!!this.multiple !== !!Array.isArray(this.selected)) {
      throw new Error(
        `Wrong type of 'selected' prop. Correct type: ${
          this.multiple ? 'array' : 'number/string'
        }`
      )
    }
    if (this.serviceOptions) {
      this.loadEndpointCache()
    }
  },
  mounted() {
    if (this.$slots.default) {
      this.hasNoTextData = this.$slots.default.some(child => {
        return (
          child.componentOptions.children.length > 1 ||
          (!child.elm.innerText &&
            !child.child.componentOptions.children[0].classList.contains(
              'r-text'
            ))
        )
      })
      this.slotOptions = this.$slots.default.map(child => {
        const title = this.hasNoTextData
          ? null
          : child.componentOptions.children[0].text ||
            child.componentOptions.children[0].componentOptions?.children?.[0]
              .text

        return {
          id: child.componentOptions.propsData.id,
          disabled: child.componentOptions.propsData.disabled,
          title: title ? title.trim() : null,
          html: this.hasNoTextData ? [child] : null
        }
      })
      const value = this.opt.find(item => item.id === this.active)
      this.text = value?.title || value?.name
    }

    this.updateMenuCoords()
    document.addEventListener('scroll', this.closeMenu, true)
    document.addEventListener(
      'mouseup',
      this.closeMenu,
      { passive: true },
      true
    )
    eventBus.on(SELECT_OPEN, this.hideByOtherSelect)
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.closeMenu)
    document.removeEventListener('scroll', this.closeMenu)
    eventBus.off(SELECT_OPEN, this.hideByOtherSelect)
  },
  methods: {
    filterInputHandler(val) {
      this.filterInputText = val
      this.$emit('input', this.active, val)
    },
    inputClickHandler() {
      if (this.disabled) return

      if (this.isFilterable && this.isMenuOpened) return
      this.toggleMenu()

      if (!this.multiple && this.isFilterable) {
        this.text = null
      }
    },
    itemClickHandler(id) {
      if (!id && id !== 0) {
        this.isMenuOpened = false
        return
      }

      const item = this.opt.find(item => item.id === id)

      this.updateMenuCoords()
      if (!this.multiple || this.hasNoTextData) {
        if (
          item.id === this.active ||
          (this.hasNoTextData && this.multiple && this.active.includes(item.id))
        ) {
          // no need to change value
          this.isMenuOpened = false
          this.$nextTick(() => this.updateMenuCoords())
          return
        }
        if (this.serviceOptions && !this.endpointDataCache[id]) {
          if (!this.endpointDataCache[id]) {
            this.endpointDataCache[id] = item
          }

          this.removeEndpointCacheItem(this.active)
        }

        this.active = this.multiple ? [item.id] : item.id
        this.text = item.title || item.name
        this.isMenuOpened = false
      } else {
        this.$refs.wrapper.focus()
        const index = this.active.indexOf(item.id)
        if (index < 0) {
          this.active.push(item.id)
          if (this.serviceOptions && !this.endpointDataCache[id]) {
            this.endpointDataCache[id] = item
          }
        } else {
          this.active = this.active.filter(el => el !== item.id)
          this.removeEndpointCacheItem(id)
        }
      }
      this.$nextTick(() => this.updateMenuCoords())
      this.$emit('change', this.active)
    },
    toggleMenu() {
      if (this.disabled) return

      this.isMenuOpened = !this.isMenuOpened

      if (this.isMenuOpened) {
        this.updateMenuCoords()
      }

      if (this.isFilterable) {
        if (this.$refs.filter) this.$refs.filter.focus()
      }

      eventBus.emit(SELECT_OPEN, this.uid)
    },
    hideByOtherSelect(id) {
      if (id !== this.uid) {
        this.isMenuOpened = false
        if (this.text && this.multiple) this.text = null

        if (!this.multiple) {
          const value = this.opt?.find(item => item.id === this.active)

          if (this.serviceOptions && !value) {
            const cacheOption = this.endpointDataCache[this.active]
            this.text = cacheOption?.name || this.active
          } else {
            this.text = value?.title || value?.name
          }
        }
      }
    },
    clearButtonClickHandler() {
      if (this.disabled) return
      this.text = ''
      if (!this.multiple) {
        this.active = null
      } else {
        this.active = []
      }
      this.$nextTick(() => {
        this.updateMenuCoords()
      })
      this.$emit('change', this.active)

      if (this.isFilterable) {
        if (this.$refs.filter) this.$refs.filter.focus()
        this.isMenuOpened = true
      }
      eventBus.emit(SELECT_OPEN, this.uid)
    },
    closeMenu(e) {
      // called on an input that has lost focus, or when scrolling, or click outside

      // for @mouseup and scroll. Checks that click was inside select
      const clickIsInsideSelect =
        !e.target.closest('.select-menu__menu-item') &&
        !e.target.closest('.r-select__input-wrapper') &&
        !e.target.closest('.r-select__dropdown-icon') &&
        !e.target.closest('.select-menu')
      if (clickIsInsideSelect) {
        if (!this.multiple) {
          const value = this.opt?.find(item => item.id === this.active)

          if (this.serviceOptions && !value) {
            const cacheOption = this.endpointDataCache[this.active]
            this.text = cacheOption?.name || this.active
          } else {
            this.text = value?.title || value?.name
          }
        } else {
          this.text = null
        }
        this.isMenuOpened = false
      }
    },
    updateMenuCoords() {
      if (!this.$refs.wrapper) return
      const rect = this.$refs.wrapper.getBoundingClientRect()
      const windowHeight = document.documentElement.clientHeight
      const menuOnTop = rect.bottom > windowHeight - 250
      this.minWidth = rect.width

      if (menuOnTop) {
        this.menuBottom = windowHeight - rect.top + 8
        this.menuTop = null
      } else {
        this.menuTop = rect.bottom + (this.dropdownStyle ? 8 : 4)
        this.menuBottom = null
      }

      this.menuLeft = rect.left
      this.menuWidth = rect.width
    },
    getOptionTitleById(id) {
      const option = this.opt?.find(item => item.id === id)

      if (this.serviceOptions && !option) {
        const cacheOption = this.endpointDataCache[id]
        return cacheOption?.name || id
      }

      return !option ? id : option?.title || option?.name
    },
    deleteSelectedItem(id) {
      if (this.disabled) return
      if (this.opt.find(item => item.id === id)?.disabled) return
      this.active = this.active.filter(item => item !== id)
      this.removeEndpointCacheItem(id)
      this.$emit('change', this.active)
    },
    removeEndpointCacheItem(id) {
      if (this.endpointDataCache[id]) {
        delete this.endpointDataCache[id]
      }
    },
    selectAll() {
      const allEnabledOptionsSelected = this.opt
        .filter(option => !option.disabled)
        .map(item => item.id)
        .every(option => this.active.includes(option))

      const disabledSelectedOptionsIds = this.opt
        .filter(option => option.disabled)
        .map(disabledOption => disabledOption.id)
        .filter(id => this.active.includes(id))

      if (allEnabledOptionsSelected) {
        this.active = disabledSelectedOptionsIds
      } else {
        this.opt
          .filter(item => !item.disabled)
          .forEach(option => {
            if (this.active.indexOf(option.id) === -1)
              this.active.push(option.id)
          })
      }

      this.$emit('change', this.active)
      this.$nextTick(() => this.updateMenuCoords())
    },
    async loadEndpointCache() {
      if (!this.active?.length && !isNumber(this.active)) return
      this.loading = true

      try {
        const { endpoint, field, params } = this.serviceOptions

        if (!endpoint) return

        const fieldName = field || 'name'
        const values = Array.isArray(this.active) ? this.active : [this.active]

        const config = {
          attribute: [{ field: 'id', op: 'in', value: values }],
          fields: ['id', fieldName]
        }
        const parameters = this.getServiceParams(config, params)
        const { data } = await this.GET(`${endpoint}?${parameters}`)

        this.setEndpointCache(fieldName, data)
        this.setEndpointOptions(fieldName, data)

        if (!this.multiple) {
          this.cacheLabel =
            this.endpointDataCache[this.active]?.[fieldName] ||
            this.endpointDataCache[this.active]?.name
        }
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    getEndpointData: debounce(async function () {
      const query = this.text?.toLowerCase()?.trim()
      this.cacheQuery = this.text?.trim()

      try {
        const { endpoint, field, params } = this.serviceOptions

        if (!endpoint) return

        const fieldName = field || 'name'
        const config = {
          order_by: { field: fieldName, type: 'asc' },
          attribute: [{ field: fieldName, op: 'like', value: query }],
          fields: ['id', fieldName]
        }
        const parameters = this.getServiceParams(config, params)
        const { data } = await this.GET(`${endpoint}?as_array&${parameters}`)

        this.setEndpointOptions(fieldName, data || [])
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    }, 256),
    getServiceParams(config, params) {
      let parameters = `filters=${JSON.stringify(config)}`

      const additionalParams = params
        ?.map(p => {
          return p.value ? `${p.prop}=${p.value}` : p.prop
        })
        ?.join('&')

      if (additionalParams) {
        parameters += `&${additionalParams}`
      }

      return parameters
    },
    setEndpointCache(fieldName, data) {
      const options = Array.isArray(data) ? data : Object.values(data)

      options?.forEach(e => {
        this.endpointDataCache[e.id] =
          fieldName !== 'name'
            ? {
                ...e,
                name: e[fieldName]
              }
            : e
      })
    },
    setEndpointOptions(fieldName, data) {
      const options = Array.isArray(data) ? data : Object.values(data)

      this.endpointOptions = !options?.length
        ? []
        : fieldName !== 'name'
        ? options?.map(d => {
            return {
              ...d,
              name: d[fieldName]
            }
          })
        : options
    }
  }
}
</script>

<style lang="scss" scoped>
.r-select {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  &.dropdown-style {
    .r-select__input-wrapper {
      background: $accent-primary-1;
      border: none;
    }

    .r-select__input {
      font-weight: 600;
      color: $button-primary-bg !important;
    }
  }

  &.show-all {
    .r-select__selected-item {
      max-width: 70px;
    }

    .r-select__selected-items {
      flex-wrap: wrap;
    }
  }

  &.disabled {
    opacity: 0.4;

    .r-select__input {
      cursor: not-allowed;
    }

    .r-select__dropdown-icon,
    .r-select__selected-item {
      opacity: 0.4;
    }

    .r-select__input-wrapper {
      cursor: not-allowed;
    }
  }

  &.opened {
    .r-select__dropdown-icon {
      transform: scale(1, -1);
    }

    .r-select__input-wrapper {
      border-color: $field-active-border;
    }
  }

  &.error {
    .r-select__input-wrapper {
      border-color: $accent-danger;
    }
  }

  &__input-wrapper {
    flex: 1;
    display: flex;
    gap: 4px;
    position: relative;
    background: $field-bg;
    cursor: pointer;
    align-items: center;
    padding: 0.25rem 2.5rem 0.25rem 0.5rem;
    border-radius: $border-radius;
    border: 1px solid $field-border;
    min-height: 36px;
    transition: border 0.25s ease;

    &.angular {
      border-radius: 0;
    }
  }

  &__input {
    background: transparent;
    border: none;
    color: $text-primary;
    font-size: 14px;
    cursor: pointer;
    min-width: 5px;
    flex: 1;
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__selected-items {
    display: flex;
    gap: 4px;
    align-items: center;
    width: 100%;
    overflow: hidden;
  }

  &__selected-item {
    flex-shrink: 0;
    border: 1px solid $dividers-high-contrast;
    border-radius: $border-radius;
    padding: 0.25rem;
    max-width: 70px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 16px;
  }

  &__clear-button {
    position: absolute;
    right: 8px;
    top: calc(50% - 14px);
  }

  &__dropdown-icon {
    position: absolute;
    right: 0.5rem;
    top: calc(50% - 14px);
    transform: scale(1);
  }

  &__no-text-data-wrapper {
    display: flex;
    align-items: center;
  }
}

.unroll-enter-active,
.unroll-leave-active {
  transition: all 0.1s ease;
  overflow: hidden;
  display: block;
  max-height: 250px;
  opacity: 1;
}

.unroll-enter,
.unroll-leave-to {
  max-height: 0;
  opacity: 0.5;
}
</style>
