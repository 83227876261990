<template>
  <div
    class="r-dropdown-child"
    :class="{ active, noHover, rtl, between }"
    @click="close"
  >
    <r-icon
      v-if="icon"
      :name="icon"
      :size="22"
    />
    <div class="r-dropdown-child__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    rtl: {
      type: Boolean,
      default: false
    },
    between: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    noHover: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.r-dropdown-child {
  padding: 0.5rem;
  cursor: pointer;
  display: grid;
  grid-gap: 0.5rem;
  grid-auto-flow: column;
  width: 100%;
  justify-content: start;

  &.between {
    justify-content: space-between;
  }

  &.rtl {
    justify-content: stretch;
    direction: rtl;
  }

  &:hover:not(.noHover) {
    background: $field-hover-bg;
  }

  &.active {
    background: $accent-primary-1;
  }

  &__content {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: $text-primary;
  }
}
</style>
