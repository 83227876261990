<template>
  <portal to="main-portal">
    <transition
      v-if="show"
      name="r-modal"
    >
      <div
        class="r-modal__wrapper"
        @mousedown.stop="closeModalByOverlay"
      >
        <div class="r-modal__overlay">
          <div
            :class="['r-modal__content', { fullscreen }]"
            :style="getStyle"
            role="dialog"
            @click.stop
          >
            <r-button
              class="r-modal__close-button"
              icon="close-delete"
              mini
              simple
              @click="closeModal"
            />
            <r-title
              v-if="title"
              class="r-modal__title"
            >
              {{ title }}
            </r-title>
            <div
              class="r-modal__body"
              :class="[{ rounded }, bodyClass]"
            >
              <slot />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    bodyClass: {
      type: String,
      default: ''
    },
    rounded: {
      type: Boolean,
      default: false
    },
    closeByOverlay: {
      type: Boolean,
      default: true
    },
    maxWidth: {
      type: Number,
      default: 640
    },
    marginTop: {
      type: Number,
      default: 64
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getStyle() {
      return `;max-width: ${this.maxWidth}px; margin: ${this.marginTop}px auto;`
    }
  },
  mounted() {
    window.addEventListener('keydown', this.escCloseModal)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.escCloseModal)
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    closeModalByOverlay(e) {
      e.composedPath()[0].className === 'r-modal__overlay' && this.closeModal()
    },
    escCloseModal(e) {
      if (this.show && e.key === 'Escape') {
        this.closeModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.r-modal {
  &__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: $modal-overlay;
    opacity: 1;
    transition: all 0.15s ease-in-out;
    z-index: 9999;
  }

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: auto;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__content {
    width: 100%;
    padding: 1.5rem 1rem;
    border-radius: $border-radius;
    background-color: $modal-bg;
    display: grid;
    grid-gap: 1rem;
    position: relative;
    box-shadow: $box-shadow-xl;

    &.fullscreen {
      width: 100vw;
      height: 100vh;
      margin: 0 !important;
      max-width: none !important;
      align-content: center;
    }
  }

  &__title {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;

    &:empty {
      display: none;
    }
  }

  &__body {
    overflow: hidden;
    display: grid;

    &.rounded {
      border-radius: $border-radius;
    }
  }

  &__close-button {
    right: 0.33rem;
    top: 0.33rem;
    position: absolute;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }

  &-enter-active,
  &-leave-active {
    transition: all 0.15s ease-in-out;
    position: absolute;
  }
}
</style>
