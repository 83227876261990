<template>
  <label :class="['r-switch', { disabled, between }]">
    <input
      type="checkbox"
      class="r-switch__input"
      :disabled="disabled"
      :checked="checked"
      @change="$emit('change', $event.target.checked)"
    />
    <div class="r-switch__wrapper">
      <span class="r-switch__switcher" />
      <r-text v-if="label">
        {{ label }}
      </r-text>
    </div>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: {
      type: Boolean
    },
    disabled: {
      type: Boolean,
      default: false
    },
    between: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
.r-switch {
  display: flex;
  padding: 0.25rem 0.25rem 0.25rem 0.5rem;
  align-items: center;

  &__input {
    height: 0;
    width: 0;
    visibility: hidden;

    &:checked + .r-switch__wrapper > .r-switch__switcher {
      background: $accent-primary;

      &:after {
        left: calc(100% - 18px);
      }
    }
  }

  &__wrapper {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
    width: 100%;
  }

  &.between {
    padding: 0.25rem 0.25rem 0.25rem 0rem;

    .r-switch__wrapper {
      justify-content: space-between;
    }
  }

  &__switcher {
    width: 38px;
    height: 16px;
    background-color: $button-secondary-bg;
    display: block;
    border-radius: 0.5rem;
    position: relative;
    transition: background-color 0.15s ease-in;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      box-shadow: $box-shadow-s;
      transform: translateY(-50%);
      left: -2px;
      width: 20px;
      height: 20px;
      background: $white;
      border-radius: 50%;
      transition: left 0.15s ease-in;
    }
  }

  &.disabled {
    opacity: 0.4;
  }
}
</style>
