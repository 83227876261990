var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{class:[
    'r-select-option',
    _vm.isSelected ? 'active' : '',
    _vm.isFocused ? 'focus' : '',
    _vm.disabled ? 'disabled' : '',
    _vm.multiple ? 'multiple' : '',
    _vm.$parent.hasNoTextData ? 'has-no-text-data' : ''
  ],attrs:{"id":_vm.id},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click', $event)}}},[_vm._t("default"),(!_vm.$slots.default && _vm.html)?_c('render-option',{attrs:{"dom":_vm.slot}}):_vm._e(),_c('div',{staticClass:"r-select-option__item-wrapper"},[(_vm.multiple && !_vm.html)?_c('r-checkbox',{attrs:{"checked":_vm.isSelected,"white-space":_vm.mode === 'wrap' ? 'unset' : 'nowrap',"label":_vm.title},on:{"click":function($event){return _vm.$emit('click', $event)}}}):_c('r-text',{attrs:{"title":_vm.title}},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }