import Vue from 'vue'
import VueRouter from 'vue-router'
import $store from '@/store'
import constants from '@/config/constants'
import i18n from '@/extesions/i18n'

Vue.use(VueRouter)

const transition = (to, from, next) => {
  document.title = `${constants.MAIN_TITLE} ${
    to.meta?.title ? `| ${i18n.t(to.meta.title)}` : ''
  }`
  const hasToken = !!$store.getters.getToken

  if (hasToken && to.name === 'login') {
    next('/app')
  } else if (!hasToken && to.name !== 'login') {
    next('/login')
  } else if (from.name !== to.name) {
    next()
  }
}

const routes = [
  {
    path: '/app',
    name: 'app',
    component: () => import('@/views/app/app'),
    beforeEnter: transition,
    meta: {
      title: 'Дорожные работы г. Сургут'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/modules/auth/'),
    beforeEnter: transition,
    meta: {
      title: 'Авторизация'
    }
  },
  { name: 'all-routes', path: '*', redirect: $store.getters.getDefaultPath }
]

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
