// Main
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './extesions/i18n'
import PortalVue from 'portal-vue'
// Styles
import '@/styles/main.scss'
import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import SvgSprite from 'vue-svg-sprite'

// Ritm-UI
import '@/components/r-ui'
import loader from './extesions/loader'
import disabler from './extesions/disabler'

// Element
import './extesions/element'

// Date formatter
import RitmDate from 'ritm-date'

// Other
import constants from '@/config/constants'
import request from 'API'

const mixin = {
  data() {
    return { ...request, ...constants }
  },
  methods: {
    $rDate(date) {
      return new RitmDate(date).setLocale('ru')
    }
  }
}

Vue.mixin(mixin)

Vue.directive('loading', loader)
Vue.directive('disabled', disabler)
Vue.use(Vuelidate)
Vue.use(PortalVue)

Vue.use(SvgSprite, {
  url: '/sprite/symbol/main.svg',
  class: ''
})

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
