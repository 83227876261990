import axios from 'axios'
import $store from '@/store'
import $router from '@/router'
import constants from '@/config/constants'

const getHeaders = props => {
  return {
    ...props,
    headers: {
      Authorization: `Bearer ${$store.getters.getToken}`,
      'Cache-Control': 'no-cache'
    }
  }
}

const validAuthHandler = e => {
  const status = e.response?.status
  const code = e.code
  switch (status) {
    case 401:
    case 422:
      $store.commit('CLEAR_USER_DATA')

      if ($router.currentRoute.name !== 'login') {
        $router.push({ path: '/login' })
      }
      break
    default:
      $store.commit('SET', [
        'notification',
        {
          type: 'danger',
          title: `Ошибка ${status} | ${code}`,
          message: 'Обратитесь к администратору'
        }
      ])
      break
  }
}

export default {
  GET(url) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${constants.URL}/${url}`, getHeaders())
        .then(data => resolve(data))
        .catch(error => {
          validAuthHandler(error)
          reject(error)
        })
    })
  },
  GET_BLOB(url) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${constants.URL}/${url}`, getHeaders({ responseType: 'blob' }))
        .then(data => resolve(data))
        .catch(error => {
          validAuthHandler(error)
          reject(error)
        })
    })
  },
  PUT(url, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${constants.URL}/${url}`, data, getHeaders())
        .then(data => resolve(data))
        .catch(error => {
          validAuthHandler(error)
          reject(error)
        })
    })
  },
  POST(url, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${constants.URL}/${url}`, data, getHeaders())
        .then(data => resolve(data))
        .catch(error => {
          validAuthHandler(error)
          reject(error)
        })
    })
  },
  DELETE(url) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${constants.URL}/${url}`, getHeaders())
        .then(data => resolve(data))
        .catch(error => {
          validAuthHandler(error)
          reject(error)
        })
    })
  }
}
