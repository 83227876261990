import baselayers from '@/config/map/baselayers'

export default {
  getLocale: state => state.locale,
  getToken: state => state.token,
  getDefaultPath: state => state.defaultPath,
  getBaseLayer: state => {
    const name = state.initialState.baseLayer

    return baselayers.find(bl => bl.name === name)
  },
  getZoom: state => state.initialState.zoom,
  getMapCenter: state => state.initialState.center,
  getTheme: state => state.theme
}
