export const jsonToGeojson = (json, geomField = 'geom') => {
  return {
    type: 'FeatureCollection',
    features: json.map(item => {
      const geometry = { ...item[geomField] }
      delete item[geomField]
      const properties = { ...item }
      return {
        type: 'Feature',
        properties,
        geometry
      }
    })
  }
}
