<template>
  <router-view />
</template>

<script>
import { toggleBodyTheme } from 'HELPERS'

export default {
  created() {
    this.syncStorageData()
    this.disableScale()
  },
  methods: {
    async syncStorageData() {
      if (localStorage.initialState) {
        const initialState = JSON.parse(localStorage.initialState)
        const keys = Object.keys(initialState)
        keys.forEach(key => {
          if (initialState[key]) {
            this.$store.commit('SET', [
              `initialState.${key}`,
              initialState[key]
            ])
          }
        })
      }
      const theme = localStorage.getItem('theme')

      this.$store.commit('SET', ['theme', theme || 'light'])
      const locale = localStorage.getItem('locale')
      this.$store.commit('SET', ['locale', locale || 'ru'])

      toggleBodyTheme()
    },
    disableScale() {
      let lastTouchEnd = 0

      document.addEventListener(
        'touchend',
        e => {
          const now = new Date().getTime()

          if (now - lastTouchEnd <= 300) {
            e.preventDefault()
          }

          lastTouchEnd = now
        },
        false
      )
    }
  }
}
</script>
