import turfBbox from '@turf/bbox'

export const getFirstSymbolId = (mapgl, type = 'symbol') => {
  const { layers } = mapgl.getStyle()

  const symbolIndex = layers.findIndex(e => e.type === 'symbol')

  if (layers[23]) {
    return layers[23].id
  }

  if (symbolIndex !== -1) {
    return layers[symbolIndex].id
  }

  return layers[layers.length - 1].id
}

export const loadCustomIcons = (mapgl, array) => {
  const MAP_ICON_SIZE = 64

  return array.map(item => {
    return new Promise(resolve => {
      const image = new Image(MAP_ICON_SIZE, MAP_ICON_SIZE)
      image.crossOrigin = 'Anonymous'
      image.style.backgroundPosition = '50%, 50%'
      image.style.backgroundSize = '100%'
      image.addEventListener('load', () => {
        if (!mapgl.hasImage(item.name)) {
          mapgl.addImage(item.name, image, { sdf: item.sdf })
        }
        resolve()
      })

      image.src = item.url
    })
  })
}

export const flyToGeom = ({
  mapgl,
  geom,
  bbox,
  coords,
  speed = 5,
  padding = 128,
  zoom: customZoom = 17,
  ...params
}) => {
  let center, zoom

  if (geom || bbox) {
    const box = bbox ?? turfBbox(geom)

    const bounds = mapgl.cameraForBounds(box, {
      padding,
      maxZoom: 17
    })
    center = bounds.center
    zoom = bounds.zoom
  } else {
    center = coords
    zoom = customZoom
  }

  mapgl.flyTo({
    center,
    zoom,
    speed,
    ...params
  })
}
